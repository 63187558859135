import Vue from "vue";
import store from "../store";
import router from "../router";

const OneSignalWrapper = {
  _init_failed: false,
  /**
   *
   * @param {(OneSignal: any) => any} func
   * @param {?number} timeoutInMilliseconds
   * @param {?boolean} first
   * @returns {Promise}
   */
  _queue(func, timeoutInMilliseconds = 20000, first = undefined) {
    if (this._init_failed) {
      return Promise.reject("not initialized");
    }
    return Promise.race([
      new Promise((resolve, reject) => {
        if (window.cordova?.platformId) {
          document.addEventListener(
            "deviceready",
            function () {
              if (!window["plugins"]?.OneSignal) return reject("not initialized");
              let result;
              try {
                result = func(window["plugins"].OneSignal);
              } catch (error) {
                return reject(error);
              }
              if (result instanceof Promise) {
                result.then(resolve).catch(reject);
              } else {
                resolve(result);
              }
            },
            first,
          );
        } else {
          window["OneSignalDeferred"].push(function (OneSignal) {
            let result;
            try {
              result = func(OneSignal);
            } catch (error) {
              return reject(error);
            }
            if (result instanceof Promise) {
              result.then(resolve).catch(reject);
            } else {
              resolve(result);
            }
          });
        }
      }),
      new Promise(function (resolve, reject) {
        setTimeout(function () {
          reject("timeout");
        }, timeoutInMilliseconds);
      }),
    ]);
  },
  /**
   *
   * @param {*} options
   * @returns {Promise} should not await for Promise to complete because it may be blocking for some time
   */
  async init(options) {
    try {
      if (window.cordova?.platformId) {
        await this._queue(
          async function (OneSignal) {
            OneSignal.initialize(options.appId);
            OneSignal.InAppMessages.addEventListener("click", (data) => {
              if (data.result.url) {
                if (/^(alvago:\/\/?)\//.test(data.result.url)) {
                  router.push(data.result.url.replace(/^(alvago:\/\/?)\//, "/")).catch(() => {});
                } else if (/^[a-zA-Z]+:\/\/(www\.)?(go|event)(\.[a-z]+)?\.alvarum\.com(\/|$)/.test(data.result.url)) {
                  router.push("/" + data.result.url.split("/").slice(3).join("/")).catch(() => {});
                } else {
                  window.open(data.result.url);
                }
              }
              return true;
            });
            OneSignal.Notifications.addEventListener("click", (data) => {
              console.log("OneSignal notification click", JSON.stringify(data.result));
              if (data.result.url) {
                if (/^(alvago:\/\/?)\//.test(data.click_url)) {
                  router.push(data.click_url.replace(/^(alvago:\/\/?)\//, "/")).catch(() => {});
                } else if (/^[a-zA-Z]+:\/\/(www\.)?(go|event)(\.[a-z]+)?\.alvarum\.com(\/|$)/.test(data.result.url)) {
                  router.push("/" + data.result.url.split("/").slice(3).join("/")).catch(() => {});
                } else {
                  window.open(data.result.url);
                }
              }
              return true;
            });
          },
          60000,
          true,
        );
      } else if (location.protocol === "https:") {
        window["OneSignalDeferred"] = window["OneSignalDeferred"] || [];
        const script = document.createElement("script");
        script.id = "onesignal-sdk";
        script.src = "https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js";
        script.defer = true;
        document.head.appendChild(script);
        await this._queue(async (OneSignal) => {
          await OneSignal.init(options);
        }, 60000);
      } else {
        this._init_failed = true;
      }
    } catch (err) {
      console.error("Failed to init OneSignal", err);
      this._init_failed = true;
    }
  },
  async prompt() {
    store.commit("NOTIFICATIONS_ENABLED", true);
    try {
      return await this._queue(async function (OneSignal) {
        const accepted = await OneSignal.Notifications.requestPermission(true);
        store.commit("NOTIFICATIONS_ENABLED", accepted !== false);
        if (accepted !== false) {
          if (typeof OneSignal.User.PushSubscription !== "undefined") {
            await OneSignal.User.PushSubscription.optIn();
          } else {
            // old API (used in Cordova)
            OneSignal.User.pushSubscription.optIn();
          }
        }
        return accepted !== false;
      });
    } catch (error) {
      console.error(error);
      store.commit("NOTIFICATIONS_ENABLED", false);
      return false;
    }
  },
  async logout() {
    try {
      this._queue(async function (OneSignal) {
        await OneSignal.logout();
      });
    } catch (err) {
      console.log("Failed to logout from onesignal", err);
    }
  },
  async unsubscribe() {
    await this._queue(async function (OneSignal) {
      if (typeof OneSignal.User.PushSubscription !== "undefined") {
        await OneSignal.User.PushSubscription.optOut();
      } else {
        // old API (used in Cordova)
        OneSignal.User.pushSubscription.optOut();
      }
    });
  },
  async updateTags() {
    try {
      const user = store.state.me;
      if (!user) return;
      const tags = {
        phone: user.phone_number,
        event: user.event_event.name,
        email: user.email,
        is_participant: user.is_participant,
        is_referent: user.is_referent,
      };
      if (user.organization_organization) {
        tags["organization"] = user.organization_organization.name;
        tags["organization_id"] = user.organization_organization.id;
      }
      if (user.team_team) {
        tags["team"] = user.team_team.name;
      }
      if (user.tags) {
        tags["tags"] = user.tags;
      }
      await this._queue(async function (OneSignal) {
        await OneSignal.login(user.registration_number || user.id);
        OneSignal.User.addTags(tags);
      });
    } catch (err) {
      console.error("Failed to update tags on onesignal", err);
    }
  },
};

Vue.prototype.$OneSignal = OneSignalWrapper;
export default OneSignalWrapper;
