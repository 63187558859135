import axios from "axios";
import store from "../store";
import Vue from "vue";
import localforage from "localforage";
import { Base64 } from "js-base64";
import router from "../router";
import "polyfill-object.fromentries";

axios.defaults.baseURL =
  localStorage.getItem("baseUrl") ||
  import.meta.env.VITE_VUE_APP_API_BASE_PATH ||
  (window.cordova?.platformId ? "https://go.preprod.alvarum.com/" : window.location.origin + "/");

// expose axios for debug purpose
window["axios"] = axios;
/**
 * Error handler
 */
axios.defaults.headers.common = {
  "X-App-Version":
    import.meta.env.VITE_VUE_APP_RELEASE || "alvarum-go-" + (window.cordova?.platformId || "web") + "@dev",
};

document.addEventListener("deviceready", function () {
  if (window.device) {
    axios.defaults.headers.common["X-Device"] =
      `${window.device.version}:${window.device.manufacturer} ${window.device.model}`;
  }
});

const auth = JSON.parse("" + localStorage.getItem("auth"));
if (auth) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${auth.access_token}`;
}

// Axios Default Adapter
const adapter = axios.getAdapter(axios.defaults.adapter);

// Cache Storage
const cacheStorage = localforage.createInstance({
  name: "axios",
});
cacheStorage.setDriver(localforage.LOCALSTORAGE);

axios["clearCache"] = async () => await cacheStorage.clear();

// @ts-ignore
axios.defaults.adapter = async (config) => {
  // @ts-ignore
  const { method, url, params, cache } = config;
  if (url[0] != "/") {
    console.warn(`Missing '/' in url: ${url}`);
  }
  const key = Base64.encodeURI(url + "?" + JSON.stringify(params));
  if (method == "get" && (navigator.onLine === false || cache)) {
    const cachedResponse = await cacheStorage.getItem(key);
    if (cachedResponse) {
      if (cache) {
        setTimeout(() =>
          axios(Object.fromEntries(Object.entries(config).filter(([key]) => key !== "cache"))).then(function (result) {
            if (typeof cache === "function") {
              cache(result);
            }
          }),
        );
      }
      return { data: cachedResponse };
    }
  }
  return await adapter(config)
    .then(async (response) => {
      if (method == "get" && cache !== false) {
        cacheStorage.setItem(key, response.data);
      }
      return response;
    })
    .catch(async (error) => {
      if (!error.response) {
        if (method == "get") {
          const cachedResponse = await cacheStorage.getItem(key);
          if (cachedResponse) return { data: cachedResponse };
          throw error;
        }
        store.commit("I18N_MESSAGE", ["error", "errors.server.notreachable"]);
        throw error;
      }
      const status = error.response.status;
      const errorData = error.response.data.error || error.response.data;
      if (status === 401) {
        if (console && console.error) console.error("401 Unauthorized", errorData);
        router.push("/logout");
      } else if (status === 400) {
        if (console && console.log) console.log("Bad request", errorData);
        if (errorData.name === "AppError") {
          store.commit("I18N_MESSAGE", ["error", errorData.message, errorData.params]);
        } else {
          //          store.commit("I18N_MESSAGE", ["error", "errors.server.validation"]);
        }
      } else if (status === 500) {
        if (console && console.log) console.log("Technical error", errorData);
        //        store.commit("I18N_MESSAGE", ["error", "errors.server.technical"]);
      } else {
        if (console && console.log) console.log("Unexpected error", status, errorData);
        //        store.commit("I18N_MESSAGE", ["error", "errors.server.unexpected"]);
      }
      throw error;
    });
};

/**
 * Unwrap
 */
axios.interceptors.response.use((r) => r && r.data);
Vue.prototype.$axios = axios;
export default axios;
