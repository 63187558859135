import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { Scroll } from "vuetify/lib/directives";
import i18n from "./i18n";

/* required for vue-tel-input */
import { VSelect, VTextField } from "vuetify/lib";
Vue.component("v-select", VSelect);
Vue.component("v-text-field", VTextField);
/* end */

Vue.use(Vuetify, {
  directives: {
    Scroll,
  },
});

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      light: {
        primary: "#3545EE",
        secondary: "#3545EE",
        accent: "#FC0B50",
        error: "#f44336",
        warning: "#ff9800",
        info: "#03a9f4",
        success: "#4caf50",
      },
      dark: {
        primary: "#CCD0FB",
        secondary: "#CEF4E9",
        accent: "#FC0B50",
        error: "#f44336",
        warning: "#ff9800",
        info: "#03a9f4",
        success: "#4caf50",
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
});
