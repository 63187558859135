import "./plugins/sentry";
import "./styles/styles.scss";
import "./plugins/filters";
import Vue from "vue";
import { attachErrorHandler } from "@sentry/vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import validator from "./plugins/validator";
import store from "./store";
import axios from "./plugins/axios";
import onesignal from "./plugins/onesignal";
import stepper from "./plugins/stepper";
import "@mdi/font/scss/materialdesignicons.scss";
import VueSocialSharing from "vue-social-sharing";
import VueMatomo from "vue-matomo";
import { setLocale } from "@/plugins/dayjs";
setLocale(i18n.locale);

attachErrorHandler(Vue, {
  Vue,
  attachProps: true,
  logErrors: !window.cordova?.platformId,
  hooks: ["activate", "mount", "update"],
  timeout: 2000,
  trackComponents: false,
});

Vue.config.productionTip = false;

let initialized = false;
let initVue = function (settings) {
  // Ensure settings are valid
  if (!settings || !settings.eventAppUrl || !settings.analytics) return;
  // Stop retry
  clearInterval(retryInternal);

  // Save settings to store
  store.commit("SETTINGS", settings);

  // Exit if already initialized
  if (initialized) return;
  initialized = true;

  // Init analytics if required
  if (settings.analytics.matomo.host && settings.analytics.matomo.siteId) {
    Vue.use(VueMatomo, {
      host: settings.analytics.matomo.host,
      siteId: settings.analytics.matomo.siteId,
      router: router,
      trackerUrl: `${settings.analytics.matomo.host}/js/`,
      trackerScriptUrl: `${settings.analytics.matomo.host}/js/`,
      preInitActions: [["enableFileTracking"], ["setCustomDimension", "1", import.meta.env.VITE_VUE_APP_RELEASE]],
    });
  }

  // @ts-ignore
  Vue.use(onesignal);
  Vue.use(VueSocialSharing);

  // Init vue
  new Vue({
    router,
    vuetify,
    i18n,
    // @ts-ignore
    validator,
    store,
    axios,
    stepper,
    render: (h) => h(App),
    mounted() {
      try {
        document.addEventListener("deviceready", () => {
          if (navigator.splashscreen) navigator.splashscreen.hide();
        });
        this["$OneSignal"].init({
          appId: store.state.settings.oneSignalApplicationId,
          safari_web_id: store.state.settings.oneSignalWebSafariId,
        });
      } catch (error) {
        console.error(error);
      }
    },
  }).$mount("#app");
};

// Load settings from backend before vue init
function loadSettings() {
  axios
    .get(`/api/public/settings?v=${import.meta.env.VITE_VUE_APP_RELEASE || "web"}`, {
      cache: initVue,
    })
    .then(initVue);
}
let retryInternal = setInterval(loadSettings, 5000);
loadSettings();
