import store from "../store";
import axios from "./axios";
import i18n from "./i18n";
import dayjs from "@/plugins/dayjs";
import Vue from "vue";

let DEVICE_ID = null;

const StepperWrapper = {
  started: false,
  isAvailable() {
    return new Promise((resolve) => {
      if (!window.cordova?.platformId) return resolve(false);
      document.addEventListener("deviceready", () => {
        if (!window["stepper"]) resolve(false);
        else {
          let done = false;
          setTimeout(() => {
            if (!done) {
              resolve(true);
              done = true;
            }
          }, 4000);
          window["stepper"].isStepCountingAvailable(
            (result) => {
              if (!done) {
                done = true;
                resolve(result !== false);
              }
            },
            (error) => {
              console.error("Failed to check Pedometer availability", error);
              if (!done) {
                done = true;
                resolve(false);
              }
            },
          );
        }
      });
    });
  },
  async pushSteps() {
    if (navigator.onLine === false) {
      console.log("offline");
      return;
    }
    // TODO: debounce and queue for offline
    const lastPushTime = parseInt(localStorage.getItem("lastPushTime")) || 0;
    const now = new Date().getTime();
    if (store.state.me && lastPushTime < now - 10 * 1000 && !/(00:0[0-2])|23:59/.test(dayjs().format("HH:mm"))) {
      // Updating lastPushTime to lock push
      localStorage.setItem("lastPushTime", now.toString());

      // Resolve dates to push
      const today = dayjs().format("YYYY-MM-DD");
      /*
       * TODO : if event is started days ago, try to get history steps from challenge start day
       * i.e. set lastSuccessPushDate to event start date
       */
      let lastSuccessPushDate = localStorage.getItem("lastSuccessPushDate") || today;
      const fiveDaysAgo = dayjs().add(-5, "day").format("YYYY-MM-DD");
      if (lastSuccessPushDate < fiveDaysAgo) lastSuccessPushDate = fiveDaysAgo;
      console.log("[pushSteps] Starts", lastSuccessPushDate, today);
      try {
        while (lastSuccessPushDate <= today) {
          const result = await window["stepper"].getStepsByPeriod(
            dayjs(lastSuccessPushDate).startOf("day").toDate(),
            dayjs(lastSuccessPushDate).endOf("day").toDate(),
          );
          if (new Date().getTime() - now > 300000) {
            console.error("Took more than 5min to get steps");
            break;
          }
          console.log("[pushSteps] Got steps from stepper", lastSuccessPushDate, result);
          await sendPostRequest("/api/steps/steps", {
            _stamp: now,
            day: lastSuccessPushDate,
            count: result.steps,
            manual: false,
            device_id: DEVICE_ID,
          });
          if (lastSuccessPushDate >= today) {
            break;
          }
          lastSuccessPushDate = dayjs(lastSuccessPushDate).add(1, "day").format("YYYY-MM-DD");
        }
      } catch (error) {
        console.log("Failed to push steps", error);
      }
      localStorage.setItem("lastSuccessPushDate", lastSuccessPushDate);
    }
  },
  async initBackgroundFetch() {
    console.log("[BackgroundFetch] Init");
    if (window.cordova?.platformId === "android") {
      // set params for headless task
      if (JSON.parse(localStorage.getItem("auth"))) {
        window["NativeStorage"].setItem("headless_task_settings", {
          api_base_url:
            localStorage.getItem("baseUrl") ||
            import.meta.env.VITE_VUE_APP_API_BASE_PATH ||
            (window.cordova?.platformId ? "https://go.preprod.alvarum.com/" : window.location.origin + "/"),
          access_token: JSON.parse(localStorage.getItem("auth")).access_token,
          device_id: DEVICE_ID,
          release:
            import.meta.env.VITE_VUE_APP_RELEASE || "alvarum-go-" + (window.cordova?.platformId || "web") + "@dev",
          time_zone: store.state.me.event_event.timezone,
        });
      }
    }
    const status = await window["BackgroundFetch"].configure(
      {
        minimumFetchInterval: 15,
        stopOnTerminate: false,
        startOnBoot: true,
        enableHeadless: true,
        requiredNetworkType: window["BackgroundFetch"].NETWORK_TYPE_ANY,
      },
      async (taskId) => {
        console.log("[BackgroundFetch] Starting", taskId);
        // TODO: fetch steps from db
        await this.pushSteps();
        console.log("[BackgroundFetch] Completing", taskId);
        window["BackgroundFetch"].finish(taskId);
      },
      async (taskId) => {
        console.error("[BackgroundFetch] TIMEOUT: ", taskId);
        window["BackgroundFetch"].finish(taskId);
      },
    );
    if (status === window["BackgroundFetch"].STATUS_DENIED) {
      console.error("[BackgroundFetch] DENIED", status);
    } else {
      console.log("[BackgroundFetch] STARTS: ", status);
    }
  },
  requestPermissions() {
    return new Promise((resolve) => {
      if (window.cordova?.platformId === "ios") {
        resolve(true);
        return;
      }
      window["stepper"].requestPermission(
        (status) => {
          if (status === false) {
            resolve(false);
            return;
          }
          window["stepper"].disableBatteryOptimizations(
            (status) => {
              console.log("disableBatteryOptimizations", status);
              resolve(true);
            },
            () => {
              resolve(true);
            },
          );
        },
        (error) => {
          console.error("Permission refused", error);
          resolve(false);
        },
      );
    });
  },
  enable() {
    return new Promise((resolve, reject) => {
      if (!window["stepper"]) reject("unsupported platform");
      store.commit("PEDOMETER_ENABLED", true);
      let done = false;
      this.requestPermissions().then((granted) => {
        if (granted === false) {
          done = true;
          store.commit("PEDOMETER_ENABLED", false);
          resolve(false);
          return;
        }
        this.attachListener().then((result) => {
          if (!done) {
            done = true;
            store.commit("PEDOMETER_ENABLED", !!result);
            if (result) {
              setTimeout(() => this.initBackgroundFetch());
            }
            resolve(!!result);
          }
        });
        setTimeout(() => {
          if (!done) {
            done = true;
            setTimeout(() => this.initBackgroundFetch());
            resolve(true);
          }
        }, 3000);
      });
    });
  },
  attachListener() {
    return new Promise((resolve) => {
      let done = false;
      window["stepper"].startStepperUpdates(
        {
          offset: 0,
          pedometerIsCountingText: i18n.t("pedometer.notification.title"),
          pedometerStepsToGoFormatText: i18n.t("pedometer.notification.no_step"),
          pedometerYourProgressFormatText: i18n.t("pedometer.notification.no_step"),
          pedometerGoalReachedFormatText: i18n.t("pedometer.notification.steps", ["%s"]),
          timeZone: store.state.me.event_event.timezone,
        },
        (result) => {
          this.started = true;
          if (store.state.lastStepUpdate && !dayjs().isSame(store.state.lastStepUpdate, "day")) {
            store.commit("TODAY_STEPS", null);
          }
          if (result.steps_today < 0) result.steps_today = 0;
          store.commit("TODAY_STEPS", result.steps_today);
          if (!done) {
            done = true;
            resolve(true);
          }
          setTimeout(() => this.pushSteps());
        },
        (error) => {
          console.error("Pedometer error", error);
          if (!done) {
            done = true;
            resolve(false);
          }
        },
      );
    });
  },
  disable() {
    return new Promise((resolve, reject) => {
      if (!window["stepper"]) reject("unsupported platform");
      window["stepper"].stopStepperUpdates(
        () => {
          store.commit("PEDOMETER_ENABLED", false);
          window["BackgroundFetch"].stop(
            (taskId) => {
              console.log("[BackgroundFetch] Stoped", taskId);
            },
            (error) => {
              console.log("[BackgroundFetch] Failed to stop", error);
            },
          );
          resolve(true);
        },
        (error) => {
          console.error("Failed to stop pedometer", error);
          resolve(false);
        },
      );
    });
  },
};

// reattach pedometer on boot if enabled
document.addEventListener("deviceready", async () => {
  DEVICE_ID = window.device
    ? `${window.device.uuid}:${window.device.version}:${window.device.manufacturer} ${window.device.model}`.substring(
        0,
        60,
      )
    : null;
  if (store.state.isPedometerEnabled) {
    await StepperWrapper.attachListener();
    await StepperWrapper.initBackgroundFetch();
  }
});

document.addEventListener("resume", async () => {
  if (store.state.isPedometerEnabled) {
    // reset count on new day
    if (StepperWrapper.started) {
      setTimeout(StepperWrapper.pushSteps);
    }
  }
});

function sendPostRequest(url, data) {
  // Use Http client which works in background
  return new Promise((resolve, reject) => {
    if (!window.cordova["plugin"] || !window.cordova["plugin"].http) reject("HTTP client not loaded");
    window.cordova["plugin"].http.sendRequest(
      axios.defaults.baseURL + url.replace(/^\//, ""),
      {
        method: "post",
        data,
        serializer: "json",
        headers: axios.defaults.headers.common,
      },
      (response) => {
        if (response.status < 400) resolve();
        else reject("Request failed with status " + response.status + " " + response.data);
      },
      (response) => reject("Request failed: " + response.error),
    );
  });
}

Vue.prototype.$stepper = StepperWrapper;
export default StepperWrapper;
