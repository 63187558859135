import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/fr";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);

export const setLocale = (l) => dayjs.locale(l);

const timezonedDayjs = (...args) => {
  return dayjs(...args).tz();
};

const timezonedUnix = (value) => {
  return dayjs.unix(value).tz();
};

timezonedDayjs.unix = timezonedUnix;
timezonedDayjs.setTimezone = function setTimezone(timezone) {
  if (typeof timezone !== "undefined") {
    if (timezone) {
      dayjs.tz.setDefault(timezone);
    } else {
      dayjs.tz.setDefault();
    }
  } else {
    dayjs.tz.setDefault("Europe/Paris");
  }
};
timezonedDayjs["duration"] = dayjs["duration"];

export default timezonedDayjs;
