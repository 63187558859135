import Vue from "vue";
import Vuex from "vuex";
import axios from "./plugins/axios";
import router from "./router";
import stepper from "./plugins/stepper";
import onesignal from "./plugins/onesignal";
import dayjs from "@/plugins/dayjs";

Vue.use(Vuex);

let interval;

const store = new Vuex.Store({
  state: {
    messages: [],
    me: JSON.parse(localStorage.getItem("me") || "null"),
    loading: false,
    serverTime: null,
    todaySteps: JSON.parse(localStorage.getItem("todaySteps") || "null"),
    lastStepUpdate: localStorage.getItem("lastStepUpdate")
      ? dayjs(JSON.parse(localStorage.getItem("lastStepUpdate"))).toDate()
      : null,
    isPedometerEnabled: localStorage.getItem("isPedometerEnabled") === "true" || null,
    isNotificationsEnabled: localStorage.getItem("isNotificationsEnabled") === "true",
    auth: JSON.parse(localStorage.getItem("auth") || "null"),
    event: null,
    likes: JSON.parse(localStorage.getItem("likes") || "[]"),
    idle: false,
    sharing: false,
    developerMode:
      !import.meta.env.VITE_VUE_APP_API_BASE_PATH ||
      (localStorage.getItem("baseUrl") &&
        localStorage.getItem("baseUrl") !== import.meta.env.VITE_VUE_APP_API_BASE_PATH),
  },
  mutations: {
    IDLE(state, idle) {
      state.idle = !!idle;
      if (!idle) {
        state.sharing = false;
      }
    },
    SHARING(state, sharing) {
      state.sharing = !!sharing;
    },
    LOADING(state, loading) {
      state.loading = !!loading;
    },
    SETTINGS(state, settings) {
      state.settings = settings;
    },
    EVENT(state, event) {
      state.event = event;
      dayjs.setTimezone(event.timezone);
    },
    SUCCESS(state, message) {
      state.messages.push({
        type: "success",
        message: message,
        timeout: 4000,
      });
    },
    ERROR(state, message) {
      state.messages.push({
        type: "error",
        message: message,
      });
    },
    WARNING(state, message) {
      state.messages.push({
        type: "warning",
        message: message,
      });
    },
    INFO(state, message) {
      state.messages.push({
        type: "info",
        message: message,
        timeout: 6000,
      });
    },
    MESSAGE(state, [type, message, timeout]) {
      state.messages.push({
        type: type,
        message: message,
        timeout: timeout,
      });
    },
    I18N_MESSAGE(state, [type, key, params, timeout]) {
      state.messages.push({
        type: type,
        key: key,
        params: params,
        timeout: timeout,
      });
    },
    AUTH(state, auth) {
      state.auth = auth;
      if (auth) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${auth.access_token}`;
        localStorage.setItem("auth", JSON.stringify(auth));
      } else {
        delete axios.defaults.headers.common["Authorization"];
        localStorage.removeItem("auth");
      }
    },
    LIKES(state, photoId) {
      const index = state.likes.indexOf(photoId);
      const likes = state.likes || [];
      if (index > -1) {
        likes.splice(index, 1);
      } else {
        likes.push(photoId);
      }
      state.likes = likes;
      localStorage.setItem("likes", JSON.stringify(likes));
    },
    ME(state, me) {
      if (me && !me.event_event) {
        console.error("Wrong ME data", me);
        router.push("/logout").catch(() => {});
        return;
      }
      state.me = me;
      if (me) {
        dayjs.setTimezone(me.event_event.timezone);
      }
      localStorage.setItem("me", JSON.stringify(me));
      if (window["Sentry"] && window["Sentry"].setUser) {
        if (me) {
          window["Sentry"].setUser({
            id: me.registration_number || me.id,
            email: me.email,
            username: me.phone_number,
          });
        } else {
          window["Sentry"].configureScope((scope) => scope.setUser(null));
        }
      }
      if (window["_paq"] && me) {
        window["_paq"].push(["setUserId", me.registration_number || me.id]);
        window["_paq"].push(["setCustomDimension", "2", me.event_event.name]);
        if (me.organization_organization) {
          window["_paq"].push(["setCustomDimension", "3", me.organization_organization.name]);
        }
      }
    },
    INCREMENT_SERVER_TIME(state) {
      const newTime = dayjs();
      if (!newTime.isSame(state.serverTime, "day")) {
        state.todaySteps = 0;
        localStorage.setItem("todaySteps", "0");
      }
      state.serverTime = newTime.toDate();
    },
    TODAY_STEPS(state, todaySteps) {
      state.todaySteps = todaySteps;
      state.lastStepUpdate = dayjs();
      localStorage.setItem("todaySteps", JSON.stringify(state.todaySteps));
      localStorage.setItem("lastStepUpdate", JSON.stringify(state.lastStepUpdate.format()));
    },
    PEDOMETER_ENABLED(state, isPedometerEnabled) {
      if (typeof isPedometerEnabled !== "boolean")
        console.error("Invalid value " + isPedometerEnabled + " for isPedometerEnabled", new Error());
      state.isPedometerEnabled = !!isPedometerEnabled;
      localStorage.setItem("isPedometerEnabled", JSON.stringify(state.isPedometerEnabled));
    },
    NOTIFICATIONS_ENABLED(state, isNotificationsEnabled) {
      if (typeof isNotificationsEnabled !== "boolean")
        console.error("Invalid value " + isNotificationsEnabled + " for isNotificationsEnabled", new Error());
      state.isNotificationsEnabled = !!isNotificationsEnabled;
      localStorage.setItem("isNotificationsEnabled", JSON.stringify(state.isNotificationsEnabled));
    },
  },
  actions: {
    ENABLE_DEVELOPER_MODE({ state }) {
      state.developerMode = true;
    },
    async LOGOUT({ state }) {
      if (state.isPedometerEnabled) await stepper.disable();
      await onesignal.logout();
      await axios["clearCache"]();
      for (let key of Object.keys(localStorage)) {
        // clear localStorage except following keys
        if (["locale", "baseUrl", "likes"].indexOf(key) === -1) {
          localStorage.removeItem(key);
        }
      }
    },
    async RELOAD_USER({ commit }) {
      const me = await axios.get("/api/auth/me", {
        params:
          this.state.auth && this.state.auth.shadow
            ? {
                shadow: this.state.auth.shadow,
              }
            : undefined,
      });
      if (!me) {
        console.error("JWT token may be invalid or expired");
        return router.push("/logout").catch(() => {});
      }
      commit("ME", me);
    },
    async AUTHENTICATE({ commit, state }, auth) {
      /**
       * Load auth if not passed in parameters
       */
      if (typeof auth === "undefined") {
        if (state.auth) {
          auth = state.auth;
        } else {
          auth = JSON.parse("" + localStorage.getItem("auth"));
        }
      }
      if (!auth || auth.auth == false) {
        return null;
      }
      /**
       * Calculate expires_date if not set
       */
      if (!auth.expires_in) {
        auth.expires_in = 31 * 24 * 3600;
        auth.expires_date = null;
      }
      if (!auth.expires_date) {
        auth.expires_date = new Date().getTime() + auth.expires_in * 1000;
      }
      if (auth.expires_date < new Date().getTime() + 30000) {
        throw new Error("JWT token is expired");
      }
      /**
       * Don't load me from server if already loaded and auth still valid
       */
      if (state.auth === auth && state.me) {
        return state.me;
      }
      /**
       * Load me from server
       */
      commit("AUTH", auth);
      const me = await axios.get("/api/auth/me", {
        params:
          this.state.auth && this.state.auth.shadow
            ? {
                shadow: this.state.auth.shadow,
              }
            : undefined,
      });
      if (!me) {
        throw new Error("JWT token is invalid");
      }
      commit("ME", me);
      return state.me;
    },
    async LOAD_SERVER_TIME({ commit, state }) {
      if (interval) clearInterval(interval);
      interval = setInterval(() => commit("INCREMENT_SERVER_TIME"), 30000);
      state.serverTime = dayjs().toDate();
    },
  },
});

if (store.state.me) {
  dayjs.setTimezone(store.state.me.event_event.timezone);
}

document.addEventListener("resume", () => {
  store.commit("IDLE", false);
});

document.addEventListener("pause", () => {
  store.commit("IDLE", true);
});

export function useStore() {
  return store;
}

export default store;
