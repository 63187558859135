import Vue from "vue";
import VueI18n from "vue-i18n";
import { createI18n, castToVueI18n } from "vue-i18n-bridge";
// TODO : load dynamically
import en from "../locales/en.json";
import fr from "../locales/fr.json";
const messages = { en, fr };

Vue.use(VueI18n, { bridge: true });

function getPreferedLanguage(available) {
  if (typeof navigator === "object" && typeof navigator.languages === "object") {
    for (let lang of navigator.languages) {
      let short = lang.slice(0, 2);
      if (available.indexOf(short) !== -1) return short;
    }
  }
}

let savedLocale;
if (!!window.localStorage && typeof localStorage.getItem === "function") {
  savedLocale = localStorage.getItem("locale");
}
const i18n = castToVueI18n(
  createI18n(
    {
      //      allowComposition: true,
      locale: savedLocale || getPreferedLanguage(Object.keys(messages)) || "en",
      fallbackLocale: "en",
      silentFallbackWarn: true,
      warnHtmlInMessage: "off",
      warnHtmlMessage: false,
      messages,
    },
    VueI18n,
  ),
);

i18n["vm"]["$watch"](
  "locale",
  function (locale) {
    if (document.documentElement) {
      document.documentElement.lang = locale;
      document.documentElement.translate = true;
    }
    if (window.localStorage && typeof localStorage.setItem === "function") {
      localStorage.setItem("locale", locale);
    }
  },
  { immediate: true },
);

Vue.use(i18n);

export const $t = i18n["t"];

export function useI18n() {
  return {
    t: (key, data) => i18n.t(key, data),
    tc: (key, data) => i18n.tc(key, data),
    te: (key, data) => i18n.te(key, data),
    tm: (key) => i18n.tm(key),
    d: (key, data) => i18n.d(key, data),
    n: (key, data) => i18n.n(key, data),
  };
}

export const locale = i18n.locale;

export default i18n;
