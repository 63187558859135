<template>
  <v-app
    ref="app"
    :class="{
      'primary-color-is-bright': isPrimaryColorBright,
      'secondary-color-is-bright': isSecondaryColorBright,
      'accent-color-is-bright': isAccentColorBright,
    }"
  >
    <Theme v-model="event" />
    <v-snackbar
      v-for="(message, index) in $store.state.messages"
      :key="index"
      :color="message.type || 'info'"
      value="message.message"
      top
      :timeout="message.timeout || 7000"
      class="mt-5"
    >
      {{ message.key ? $t(message.key, message.params) : message.message }}
      <v-icon color="white" v-if="message.reactionAddons">{{ message.reactionAddons.type }}</v-icon>
    </v-snackbar>
    <v-fade-transition mode="out-in">
      <v-overlay :value="$store.state.loading" opacity="0.7">
        <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
      </v-overlay>
    </v-fade-transition>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
    <v-system-bar color="grey lighten-2" class="text-caption" v-if="this.$route.meta.systembar">
      <div class="text--primary">© {{ new Date().getFullYear() }} Alvarum</div>
      <v-spacer />
      <div v-if="isStepperAvailable">
        <v-icon color="success">mdi-check-bold</v-icon>
        {{ $t("system.stepper.available") }}
      </div>
      <div v-else-if="isStepperAvailable === false" class="text--disabled">
        {{ $t("system.stepper.notavailable") }}
      </div>
    </v-system-bar>
  </v-app>
</template>

<script>
import Theme from "./components/theme.vue";
import isColorBright from "./util/isColorBright";
import stepper from "./plugins/stepper";

export default defineComponent({
  name: "App",
  components: {
    Theme,
  },
  data: () => ({
    isStepperAvailable: null,
  }),
  watch: {
    async route() {
      await this.detectStepperSupport();
    },
  },
  async mounted() {
    await this.detectStepperSupport();
  },
  computed: {
    route() {
      return this.$route;
    },
    event() {
      if (!this.$store.state.me) {
        return this.$store.state.event;
      }
      return this.$store.state.me.event_event;
    },
    isPrimaryColorBright() {
      return isColorBright(this.$vuetify.theme.themes["light"].primary);
    },
    isSecondaryColorBright() {
      return isColorBright(this.$vuetify.theme.themes["light"].secondary);
    },
    isAccentColorBright() {
      return isColorBright(this.$vuetify.theme.themes["light"].accent);
    },
  },
  methods: {
    async detectStepperSupport() {
      if (window.cordova?.platformId && this.route && this.route.meta.systembar && this.isStepperAvailable == null) {
        this.isStepperAvailable = await stepper.isAvailable();
        await this.$axios.post("/api/public/report-stepper-support", {
          uuid: window.device && window.device.uuid,
          version: window.device && window.device.version,
          manufacturer: window.device && window.device.manufacturer,
          model: window.device && window.device.model,
          stepper_supported: this.isStepperAvailable,
        });
      }
    },
  },
});
</script>

<style>
html,
body {
  min-height: 100vh;
}

.show-only-on-hover {
  opacity: 0;
}

td:hover .show-only-on-hover {
  opacity: 1;
}

#app {
  background: #ebeded;
  min-height: 100vh;
}

.v-application--wrap {
  min-height: 100% !important;
  min-height: -webkit-fill-available !important;
}

.v-navigation-drawer--is-mobile {
  height: 100% !important;
  height: -webkit-fill-available !important;
}
</style>
