import { init } from "@sentry/browser";
import { CaptureConsole } from "@sentry/integrations";

if (import.meta.env.PROD) {
  document.addEventListener(
    "deviceready",
    function () {
      cordova.require("sentry-cordova.Sentry").init({
        dsn: "https://556bbff94f764d268c43ae144354cec0@o1039846.ingest.sentry.io/6008720",
        release: import.meta.env.VITE_VUE_APP_RELEASE,
        ignoreErrors: ["Network Error", "AppError"],
        integrations: [
          new CaptureConsole({
            levels: ["error"],
          }),
        ],
      });
    },
    true,
  );
  init({
    dsn: "https://556bbff94f764d268c43ae144354cec0@o1039846.ingest.sentry.io/6008720",
    release: import.meta.env.VITE_VUE_APP_RELEASE,
    ignoreErrors: ["Network Error", "AppError"],
    integrations: [
      new CaptureConsole({
        levels: ["error"],
      }),
    ],
  });
}
