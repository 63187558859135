import dayjs from "@/plugins/dayjs";
import Vue from "vue";

export const dateshort = Vue.filter("dateshort", function (value) {
  if (!value) return "";
  return dayjs(value).format("DD MMM");
});

export const date = Vue.filter("date", function (value) {
  if (!value) return "";
  return dayjs(value).format("DD/MM/YYYY");
});

export const datelong = Vue.filter("datelong", function (value) {
  if (!value) return "";
  return dayjs(value).format("DD MMMM");
});

export const time = Vue.filter("time", function (value) {
  if (!value) return "";
  return dayjs(value).format("HH:mm:ss");
});

export const datetime = Vue.filter("datetime", function (value) {
  if (!value) return "";
  return dayjs(value).format("DD MMMM, HH:mm").replace(", 00:00", "");
});

export const datetime_short = Vue.filter("datetime_short", function (value) {
  if (!value) return "";
  return dayjs(value).format("DD/MM HH:mm");
});

export const duration = Vue.filter("duration", function (value) {
  if (!value) return "";
  return `${+value / 60 == 0 ? "" : +value / 60 + "h"}${value % 60 == 0 ? "" : (value % 60) + "m"}`;
});

export const amount = Vue.filter("amount", function (value, currency) {
  return (
    (Number(value) || 0)
      .toFixed(2)
      .replace(/-/g, "-\u00a0")
      .replace(/(\d)(?=(\d{3})+(\.|$))/g, "$1\u00a0")
      .replace(".", ",") +
    (currency
      ? "\u00a0" +
        ({
          EUR: "€",
          USD: "$",
          GBP: "£",
        }[currency] || currency)
      : "")
  );
});

export const suffixNumber = Vue.filter("suffixNumber", function (number) {
  if (!number) return 0;
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;
  const suffix = ["", "k", "M", "G"][tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;
  return scaled.toFixed(1).replace(".0", "").replace(".", ",") + suffix;
});

export const count = Vue.filter("count", function (x) {
  if (!x) return 0;
  return x
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .replace(/\./g, ",");
});

export const weekRange = Vue.filter("weekRange", function (date) {
  let startOfYear = dayjs(date).startOf("year");
  let startOfWeek = dayjs(date).startOf("week");
  return (
    (startOfWeek.isBefore(startOfYear) ? startOfYear.format("DD/MM/YYYY") : startOfWeek.format("DD/MM/YYYY")) +
    " - " +
    dayjs(date).endOf("week").format("DD/MM/YYYY")
  );
});

export const percentage = Vue.filter("percentage", function (value) {
  if (!value || value > 100) return "";
  const roundedValue = (Math.round(value * 1000) / 10).toFixed(0).replace(".", ",");
  return `${roundedValue}%`;
});

export const number = Vue.filter("number", function (value) {
  if (!value) return 0;
  return value.toLocaleString("fr-FR", { maximumFractionDigits: 0 });
});
