<template>
  <component :is="`style`" v-html="style"></component>
</template>
<script>
export default defineComponent({
  props: {
    value: Object,
  },
  data: () => ({
    style: "",
  }),
  mounted() {
    this.updateTheme();
  },
  methods: {
    updateTheme() {
      if (this.value) {
        if (this.value.main_color) {
          this.$vuetify.theme.themes["light"].primary = this.value.main_color;
        }
        if (this.value.button_color) {
          this.$vuetify.theme.themes["light"].secondary = this.value.button_color;
        }
        if (this.value.bg_url) {
          this.style = `main::before {
              content:"";
              position:absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-image:url('${this.value.bg_url}');
              background-position: center top;
              background-repeat: repeat;
            }`;
        }
      }
    },
  },
  watch: {
    value: {
      handler() {
        this.updateTheme();
      },
    },
  },
});
</script>

<style></style>
